<template>
  <div class="pa-5">

    <template>

      <v-row>
        <v-col
            cols="12"
            sm="4"
            class="text-center"
        >
          <br><br>

          <v-date-picker
              v-model="date"

              locale="ru-ru"
              color="green"
              min="2021-06-12"
              first-day-of-week="1"
          ></v-date-picker>

          <br>

          <v-btn
              color="green"
              dark
              @click="addEvent"
              :loading="loading"
          >
            Добавить мероприятие
          </v-btn>

          <br><br>
          {{res}}

        </v-col>

        <v-col
            cols="12"
            sm="8"
        >

          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >

                <v-autocomplete

                    :disabled="!showlist_ready"
                    v-model="show_id"
                    :items="showlist"
                    item-text="name"
                    item-value="id"
                    label="Событие"
                    required
                    prepend-icon="mdi-script-text-outline"
                ></v-autocomplete>

              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="10"
              >
                <v-select
                    :disabled="!layouts_ready"
                    v-model="layout_id"
                    :items="layouts"
                    item-text="name"
                    item-value="id"
                    label="Зал"
                    required
                    @change="loadLayoutMaps"
                    prepend-icon="mdi-periodic-table"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="2"
              >
                <v-btn
                    elevation="2"
                    outlined
                    icon
                    color="blue"
                    @click="openPicker('sp')"
                >
                  <v-icon dark>
                    mdi-layers-search-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="10"
              >
                <v-select
                    v-model="layout_pm"
                    :items="layout_PMs"
                    :disabled="!layouts_PM_ready"
                    item-text="name"
                    item-value="id"
                    label="Ценовые зоны"
                    :hint="entry_message"
                    :persistent-hint="only_entry"
                    required
                    prepend-icon="mdi-cash-multiple"
                    @change="onChangeSelectPM"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="2"
              >
                <v-btn
                    elevation="2"
                    outlined
                    icon
                    color="red"
                    :disabled="!layouts_PM_ready"
                    @click="openPicker('pm')"
                >
                  <v-icon dark>
                    mdi-layers-search-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="10"
              >
                <v-select
                    v-model="layout_am"
                    :items="layout_AMs"
                    :disabled="!layouts_AM_ready"
                    item-text="name"
                    item-value="id"
                    label="Предбронь"
                    required
                    prepend-icon="mdi-bookmark-outline"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="2"
              >
                <v-btn
                    elevation="2"
                    outlined
                    icon
                    color="orange"
                    :disabled="!layouts_AM_ready"
                    @click="openPicker('am')"
                >
                  <v-icon dark>
                    mdi-layers-search-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="8"
              >
                <v-menu
                    ref="menu_date"
                    v-model="menu_date"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                        v-model="date"

                        chips
                        small-chips
                        label="Дата проведения"
                        readonly
                        v-bind="attrs"
                        prepend-icon="mdi-calendar"
                        v-on="on"
                    ></v-combobox>
                  </template>
                </v-menu>

              </v-col>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-menu
                    ref="menu_time"
                    v-model="menu_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="490px"
                    min-width="490px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="time"
                        label="Время"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="menu_time"
                      v-model="time"
                      full-width
                      landscape
                      format="24hr"
                      @click:minute="$refs.menu_time.save(time)"
                  ></v-time-picker>
                </v-menu>

              </v-col>
            </v-row>


            <v-card class="pa-5 mt-10 text-center" :disabled="!(zone_count>0)">

              <p class="text-h4 text-center">Цены на билеты</p>

              <v-card-subtitle v-show="!(zone_count>0)">
                Поля появятся после указания ценовой раскладки
              </v-card-subtitle>

              <template v-if="only_entry">

                <v-card-subtitle>
                  Билеты на вход по категориям
                </v-card-subtitle>

                <v-row v-for="n in zone_count" :key="n">
                  <v-col
                      cols="12"
                      sm="6"
                  >
                    <v-select
                        v-model="types[n-1]"
                        :items="ticket_type"
                        item-text="name"
                        item-value="id"
                        :value="3"
                        :label="'Категория '+n"
                        required

                    >
                      <template #prepend-inner>
                        <v-icon :color="price_colors[n]">
                          mdi-rhombus
                        </v-icon>
                      </template>

                    </v-select>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                  >
                    <v-text-field
                        v-model="prices[n-1]"
                        :label="'Цена '+n"
                        placeholder="300"
                        suffix="руб"
                        type="number"
                        min="0"
                    >
                    </v-text-field>
                  </v-col>

                </v-row>

              </template>


              <template v-else>
                <div class="d-flex align-content-center flex-wrap text-center">
                  <v-col v-for="n in zone_count" :key="n"

                         cols="4"
                         style="min-width: 100px; max-width: 100%;"
                         class="flex-grow-1 flex-shrink-0"

                  >
                    <v-text-field
                        v-model="prices[n-1]"
                        :label="'Зона '+n"
                        placeholder="300"
                        suffix="руб"
                        type="number"
                        min="0"
                        outlined
                    >
                      <template #prepend-inner>
                        <v-icon :color="price_colors[n]">
                          mdi-rhombus
                        </v-icon>
                      </template>


                    </v-text-field>


                  </v-col>
                </div>
              </template>


            </v-card>

          </v-container>




          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >



          </v-form>


        </v-col>

      </v-row>
    </template>





      <template>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >

          </v-col>
          <v-col
              cols="12"
              sm="6"
          >

          </v-col>
        </v-row>
      </template>



      <template>
        <v-row>
          <v-col
              cols="11"
              sm="5"
          >

          </v-col>


        </v-row>
      </template>



    <v-dialog
        v-model="dialog"
        max-width="980"
    >
      <v-card>
        <v-card-title class="text-h5">
          Подсмотреть раскладки
        </v-card-title>

        <v-card-text>
          <picker-map ref="picker" @returnDialog="onReturnDialog" ></picker-map>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>





  </div>
</template>

<script>
import PickerMap from "@/components/picker/PickerMap";
import user from "@/store/modules/user";
export default {
  name: "EventAdd",
  components: {PickerMap},
  data: () => ({

    res:"",
    loading: false,

    dialog:false,

    valid: true,
    show_id:0,
    layout_id: 0,
    layout_pm:0,
    layout_am:0,
    date:"",
    time:null,
    prices:[],
    types:[],


    zone_count:0,
    only_entry: false,
    entry_message: "",

    price_colors:['black', 'red', 'blue', 'green', 'purple'],

    event_ready: false,
    event: {},

    more: false,

    showlist:[],
    showlist_ready:false,

    lay_permitted_list:[],

    layouts:[],
    layouts_ready:false,

    layout_PMs:[],
    layouts_PM_ready:false,

    layout_AMs:[],
    layouts_AM_ready:false,

    dates: [],
    menu_date: false,

    menu_time: false,

    ticket_type:[],




  }),

  // computed: {
  //
  //   layout_id: function () {
  //
  //     if (this.selected>-1){
  //       return this.list[this.selected].id;
  //     }
  //     return false;
  //   },
  //
  // },

  created() {

    if (this.$store.getters.isAuthenticated){

      this.loadShows();
      this.loadLayouts();
      this.loadTicketType_mock();

    }


  },


  methods:{

    openPicker(mode){

      this.dialog = true;

      this.$nextTick(() => {


        this.$refs.picker.preparePicker({

          type: 'adm',
          mode:mode,

          layout_id:this.layout_id,
          layout_pm:this.layout_pm,
          layout_am:this.layout_am,

        });


      });


    },

    onReturnDialog(data){
      //console.log(data);

      switch (data.mode){
        case 'sp':
          this.layout_id = data.layout_id;
          this.loadLayoutMaps();
          break;
        case 'pm':
          this.layout_pm  = data.layout_pm;
          this.zone_count = data.zone_count;
          this.only_entry = Boolean(data.only_entry);

          if (this.only_entry){
            this.entry_message = "Билет на проход"
          }
          else{
            this.entry_message = ""
          }

          break;
        case 'am':
          this.layout_am = data.layout_am;
          break;
      }


    },

    onChangeSelectPM(e){

      let t = this.layout_PMs.find(item=>item.id===e);

      this.zone_count = t.zone_count;
      this.only_entry = Boolean(t.only_entry);

      this.types = [];
      //this.types = new Array(this.zone_count);


      if (this.only_entry){

        for (let i = 0; i < this.zone_count; i++) {

          console.log(i);
          console.log(this.ticket_type[i].name);

          this.types.push(this.ticket_type[i].id);

        }
      }




      //this.ticket_type.forEach(tt=>{this.types.push(tt.id)})



      if (this.only_entry){
        this.entry_message = "Билет на проход"
      }
      else{
        this.entry_message = ""
      }

    },

    loadShows(){
      let pr = this.$store.state.api.loadShowList();
      pr.then( data =>{
        this.showlist = data;

        this.showlist.sort((a,b)=>{

          if (a.name < b.name){
            return -1;
          }
          if (a.name > b.name){
            return 1;
          }
          return 0;
        })

        this.showlist_ready = true;
      })
    },

    // брать из layout.json ticket_type и спрашивать в global base наименования
    loadTicketType_mock(){

      this.ticket_type = [
        {
          id:1,
          name: "Взрослый",
          desc: "Обычный билет"
        },
        {
          id:2,
          name: "Взрослый (Foreigner)",
          desc: "Для иностранных граждан"
        },
        {
          id:3,
          name: "Детский",
          desc: "Детский билет"
        },
        {
          id:4,
          name: "Льготный",
          desc: "Льготный билет"
        }
      ];

    },


    loadLayouts(){

      let pr = this.$store.state.api.loadPermittedLayout(user.state.selectel_path);

      pr.then( data =>{

        //console.log(data);

        this.lay_permitted_list   = data.layout;
        this.place_permitted_list = data.layout_place;

        let pr2 = this.$store.state.api.loadLayouts(this.lay_permitted_list);

        pr2.then( data =>{
          this.layouts = data;
          this.layouts_ready = true;
        })

      })

    },


    loadLayoutMaps(){

      this.only_entry = false;
      this.zone_count = 0;
      this.layout_pm = 0;
      this.layout_am = 0;
      this.loadLayoutPM();
      this.loadLayoutAM();
    },


    loadLayoutPM(){

      let pr = this.$store.state.api.loadLayout_PM_list(this.layout_id);
      pr.then( data =>{
        this.layout_PMs = data.seat_zones;
        this.layouts_PM_ready = true;
      })
    },

    loadLayoutAM(){

      let pr = this.$store.state.api.loadLayout_AM_list(this.layout_id);
      pr.then( data =>{
        this.layout_AMs = data.seat_armour;
        this.layouts_AM_ready = true;
      })
    },


    addEvent(){

      // TODO заблочить кнопку отправки
      this.loading = true;

      let prices = "";

      this.prices.forEach(price=>{ prices += "/"+price });
      prices = prices.substring(1);

      let types = "";

      this.types.forEach(type=>{ types += "/"+type });
      types = types.substring(1);

      let post_data = {

        id:0,
        name:this.showlist.find(item=>item.id===this.show_id).name,
        show_id: this.show_id,
        timedate:this.date + ' ' + this.time + ':00',
        lay_id:this.layout_id,
        lay_pm_id:this.layout_pm,
        lay_am_id:this.layout_am,
        prices:prices,
        ticket_types:types,
        status:31,
        free_seat:"15",
        type: this.only_entry ? 1 : 0
      }
      console.log(post_data);

      let pr2 = this.$store.state.api.addEvent(post_data);

      pr2.then( data =>{
        console.log(data);

        this.res = "Мероприятие "+data+" добавлено";
        this.loading = false;
      })

    },




  }
}
</script>

<style scoped>

</style>
